import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #ededf4;
  padding-bottom: 30px;
  padding-top: 30px;
  @media ${device.md} {
    padding-bottom: 5px;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f2f2f3" pt={0}>
      <Container>
        <Row className="text-center">
          <Col md="4" className="mb-5 mt-5">
            <Box
              data-aos="fade-in"
              data-aos-duration="500"
              data-aos-once="true"
              data-aos-delay="50">
              <Title color="secondary">3.5X</Title>
              <Text>
                Quicker Automation. <br />
                Isolate, Feed &amp; Harvest
              </Text>
            </Box>
          </Col>
          <Col md="4" className="mb-5 mt-5">
            <Box
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-delay="50">
              <Title color="secondary">1.25X</Title>
              <Text>Faster time to clonal colony.<br />
              8-10 vs 10-12 days
              </Text>
            </Box>
          </Col>
          <Col md="4" className="mb-5 mt-5">
            <Box
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-once="true"
            data-aos-delay="50">
              <Title color="secondary">3.8X</Title>
              <Text>
                Better cloning efficiency (68%).<br />
                68% vs 16%
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
