import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import imgOfferBook from "../../assets/image/png/current_protocols_inverted.png";

const SectionStyled = styled(Section)`
  padding-bottom: 100px;
  padding-top: 0;
`;

const OfferWrapper = styled(Box)`
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.ash};
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 50px;
`;

const BtnContainer = styled(Box)`
  margin-left: 45px;
  margin-top: 30px;

  @media ${device.lg} {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const Offer = () => (
  <>
    {/* <!-- Offer section 1 --> */}
    <SectionStyled>
      <Container>
      <Row>
          <Col xs="12" className="mb-5" style={{paddingTop:50}}>
            <div className="text-center">
              <Title>The paper</Title>
            </div>
          </Col>
        </Row>
        <OfferWrapper>
          <div className="d-flex flex-wrap">
            
            <div className="pt-3 pl-5 d-flex flex-wrap">
            
              <Title variant="card" fontSize="24px" color="light" mb={3}>
                  Methods for Automated Single Cell Isolation <br /> and Sub‐Cloning of Human Pluripotent Stem Cells
              </Title>
              <Text color="lightShade" fontSize="18px" lineHeight="28px">
              The paper describes the application of three different automated cell isolation and <br />
              dispensing devices that can enhance the single‐cell cloning process for hPSCs.<br /> 
              In combination with optimized cell culture conditions, these devices <br />
               offer an attractive alternative compared to manual methods. We explore <br />
               various aspects of each device system and define protocols for their practical application.
              </Text>
              
              <img src={imgOfferBook} alt="Current_Protocols_Logo" width={250} height={70} className="img-fluid" />
              <Text color="light" fontSize="16px" lineHeight="28px">
              DOI: <u>https://doi.org/10.1002/cpsc.123</u>
              </Text>
            </div>
            
            <div className="widget-image">
              
              
            </div>
          </div>
        </OfferWrapper>
      </Container>
    </SectionStyled>
  </>
);

export default Offer;
