import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  /* border-bottom: 1px solid #eae9f2; */
  padding-top: 60px;
  padding-bottom: 60px;
`;

const CTA = () => (
  <>
    <SectionStyled bg="#F6F6F8" className="position-relative">
      <Container>
        <Row>
          <Col lg="8" xl="7">
            <div className="py-4">
              <Title>Download Whitepaper</Title>
              <Text>
              What does this whitepaper have to say? We’ve provided you with a brief synopsis of an independently authored publication which directly compares some of the commercially available single-cell cloning technology on the market. Shrinking 33 pages down to just 3 we have condensed the findings and provided some commentary of our own. 
              </Text>
            </div>
          </Col>
          <Col
            lg="4"
            xl="5"
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-md-0"
          >
            <div>
              <Button>Download Whitepaper</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default CTA;
