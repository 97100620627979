import React from "react";
import Hero from "../sections/whitepaper/Hero";
/*import Hero2 from "../sections/whitepaper/Hero2";*/
import Clients from "../sections/whitepaper/Clients";
import Feature1 from "../sections/whitepaper/Feature1";
import Fact from "../sections/whitepaper/Fact";
import Author from "../sections/whitepaper/Author";
/* Add Review here */
import Offer from "../sections/whitepaper/Offer";
import CTA from "../sections/whitepaper/CTA";
import Pricing from "../sections/whitepaper/PricingTable2";
import PageWrapper from "../sections/whitepaper/PageWrapper";

const WhitePaper = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <Clients />
        <Feature1 />
        <Fact />
        <Offer />
        <Author />
        <CTA />
        <Pricing />
      </PageWrapper>
    </>
  );
};
export default WhitePaper;
