import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Image, Span } from "../../components/Core";
import { device } from "../../utils";
import imgAuthorSeb from "../../assets/image/jpeg/authorSeb.jpg";
import imgAuthorHarald from "../../assets/image/jpeg/authorHarald.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
  `;


const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#ffffff" style={{paddingTop:30}}>
      <Container>
        <Row>
          
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
          <img src={imgAuthorSeb} style={{width: 150, margin: 3}} alt="Author" />
          <img src={imgAuthorHarald} style={{width: 150, margin: 3}} alt="Author" />
            
            
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Title variant="card" fontSize="21px">
                About the Authors
              </Title>
              <Title variant="card" fontSize="18px">
              Dr. Sebastien Dieke (Max Delbrück Center for Molecular Medicine) and Dr. Harald Stachelsche (Charité – Universitätsmedizin)
              </Title>
              
              <Text variant="small">
              The authors lead the Berlin Institute of Health (BIH) Core Facility Stem Cells group. The mission of the BIH is to support basic and translational research by facilitating all aspects of human induced pluripotent 
              stem cell (hiPSC) technology. The facility provides scientists with state-of the-art protocols and techniques for proper handling 
              and manipulation of human pluripotent stem cells. 

              </Text>
              
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
